<template>
  <div>
    <h1>Tipo de notificaciones</h1>
    <v-list v-if="items.length > 0">
      <v-list-item v-for="item in items" :key="item.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.nombre }}
          </v-list-item-title>
           <v-list-item-subtitle
           > 
           {{item.descripcion}}
           </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    
  </div>
</template>

<script>
export default {
  name: "TipoDeNotificacionesSoporte",
  data() {
    return {
      items: [],
    };
  },
  
  created() {
    this.axios
      .get("/notificaciones/tipo-notificaciones")
      .then((response) => {
        this.items = response.data;
      })
      .catch((e) => {
        console.log(e);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e}`,
          icon: "mdi-information",
          timeout: 3000,
        });
      });
  },
  
};
</script>

<style lang="scss" scoped>
</style>